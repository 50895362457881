export enum EQuickStartOperationOptions {
  AnswerType = "법률 질의하기",
  FindType = "자료 검색하기",
  Summarize = "문서 요약하기",
  Translate = "문서 번역하기",
}

export enum EIDCardOperationOptions {
  ManuallyCreateFactblock = "ManuallyCreateFactblock",
  FactblockFeed = "FactblockFeed",
  Dashboard = "Dashboard",
  ConnectFactblocks = "ConnectFactblocks",
  DeepVerify = "DeepVerify",
  MyFactBlock = "MyFactBlock",
  AnswerTypeHistory = "AnswerTypeHistory",
}

export enum EOperationOptions {
  AnswerType = "AnswerType", // AnswerType
  FindType = "FindType", // FindType
  ReflectType = "ReflectType", // ReflectType
  Summarize = "Summarize", // Summarize
  Translate = "Translate", // Translate
}

export enum Annotation {
  Approval = "Approval",
  Negation = "Negation",
  Bookmark = "Bookmark",
  Null = "Null",
}

export enum Relation {
  Explanation = "Explanation",
  Example = "Example",
  Similar = "Similar",
  Conflicting = "Conflicting",
  Null = "Null",
}

export const SearchPeriod = [
  { value: "0", label: "All" },
  { value: "3", label: "Last 3 Years" },
  { value: "5", label: "Last 5 Years" },
  { value: "10", label: "Last 10 Years" },
  { value: "1", label: "Custom Input" },
];

export const isValueFromOptions = (value: string, options: any[]) => {
  return options.some((option) => option.value === value);
};

export const AnnotationColors: Record<Annotation, string> = {
  [Annotation.Approval]: "#4ade80",
  [Annotation.Negation]: "#ef4444",
  [Annotation.Bookmark]: "#fde047",
  [Annotation.Null]: "grey",
};

export enum InternalDocumentType {
  OpinionLetter = "OpinionLetter",
  LitigationDocument = "LitigationDocument",
  Proposal = "Proposal",
  // Contract = "Contract",
  // Judgment = "Judgment",
  // Others = "Others",
}

export enum UsabilityCategory {
  AnswerAccuracy = "답변 결과",
  SearchAccuracy = "문서 검색",
  FactblockUsefulness = "팩트블럭 활용",
  Etc = "기타",
}

export enum ImprovementCategory {
  FunctionAddition = "신규 기능 추가",
  DataSourceAddition = "새로운 자료 제안",
  Etc = "기타",
}
export type FeedbackCategories = UsabilityCategory | ImprovementCategory;

export enum CallbackCodes {
  GenerateFB = "GenerateFB",
  INAPT_TOPIC = "법률 관련 질의가 아닌 것 같습니다.",
  CheckFBExists = "null",
}

export enum TooltipMessage {
  Confidence = "AI가 질의와 근거자료 내 주요 단어의 일치도, 문장 전개 유사성 등을 종합적으로 고려하여 계산한 점수입니다. 실제 내용의 관련성과는 다를 수 있으니 유의하세요.",
}
//PG, QG 없음
export enum SejongLegalGroupMap {
  AG00 = "기업자문 M&A그룹",
  BG00 = "노동그룹",
  CG00 = "공정거래그룹",
  DG00 = "규제그룹",
  EG00 = "기업금융그룹",
  FG00 = "부동산대체투자그룹",
  GG00 = "건설부동산분쟁그룹",
  HG00 = "공법분쟁그룹",
  IG00 = "금융분쟁그룹",
  JG00 = "회사분쟁그룹",
  KG00 = "IP그룹",
  LG00 = "형사그룹",
  MG00 = "조세그룹",
  NG00 = "국제분쟁그룹",
  OG00 = "해외그룹",
  RG00 = "ICT그룹",
  SG00 = "프로젝트 에너지그룹",
  TG00 = "금융규제그룹",
  미분류 = "미분류",
}

export enum KoreanLegalDocType {
  "의견서" = "의견서",
  "송무서면" = "송무서면",
  "준비서면" = "준비서면",
  "변론요지" = "변론요지",
  "제안서" = "제안서",
  "계약서" = "계약서",
  "판례" = "판례",
  "미분류" = "미분류",
  "심결" = "심결",
  "법령" = "법령",
  "유권해석" = "유권해석",
  "기타" = "기타",
}

export const DocumentTypeMapKoToEn: {
  [key: string]: PossibleDocumentTypes;
} = {
  의견서: "OpinionLetter",
  송무서면: "LitigationDocument",
  준비서면: "Brief",
  변론요지: "DefenseEssentials",
  제안서: "Proposal",
  계약서: "Contract",
  판례: "Judgment",
  미분류: "Others",
  심결: "Adjudication",
  법령: "Legislation",
  유권해석: "InterpretativeGuidelines",
  기타: "기타",
};

export const DocumentTypeMapEnToKo: {
  [key: string]: string;
} = {
  OpinionLetter: "의견서",
  LitigationDocument: "송무서면",
  Brief: "준비서면",
  DefenseEssentials: "변론요지",
  Proposal: "제안서",
  Contract: "계약서",
  Judgment: "판례",
  Others: "미분류",
  Adjudication: "심결",
  Legislation: "법령",
  InterpretativeGuidelines: "유권해석",
  기타: "기타",
  ExternalDB: "외부DB",
};
export enum CasenoteEndpoint {
  판례 = "judgment",
  심결 = "adjudication",
  법령 = "law-title",
  법령본문 = "law-body",
  유권해석 = "interpretative-guidelines",
  본문추출 = "html-body",
  다운로드 = "download",
}

export const DocumentTypeMapKoToCasenoteEndpoint: {
  [key: string]: CasenoteEndpoint[keyof CasenoteEndpoint];
} = {
  판례: CasenoteEndpoint.판례,
  심결: CasenoteEndpoint.심결,
  법령: CasenoteEndpoint.법령본문,
  유권해석: CasenoteEndpoint.유권해석,
};

export enum TooltipMessage {
  Credibility = "AI가 질의와 근거자료 내 주요 단어의 일치도, 문장 전개 유사성 등을 종합적으로 고려하여 계산한 점수입니다. 실제 내용의 관련성과는 다를 수 있으니 유의하세요.",
}
