import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "@/lib/store";
import { signOut } from "next-auth/react";
import { EIDCardOperationOptions, Relation } from "@/globalEnum";
import { v4 as uuidv4 } from "uuid";
const SUPERUSER = "admin";
const initialUserState: User = {
  userId: "",
  legalName: "",
  email: "",
  bookmarkedFbIdList: ["fb001", "fb002"] as string[], // dummy data set as default bookmark
  menuRouter: null,
  customRelations: [],
  connectionStatus: {
    source: null,
    destination: [],
    arrow: {},
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,

  reducers: {
    createUserInstance(state, action: PayloadAction<{ email: string; name: string }>) {
      // console.log("Before update:", JSON.stringify(state));
      const { email, name } = action.payload;
      state.email = email;
      state.userId = email;
      state.legalName = name;
      // console.log("After update:", JSON.stringify(state));
    },
    toggleUpdateBookmarkedFbIdList(state, action: PayloadAction<string>) {
      const target = state.bookmarkedFbIdList?.find((id) => id === action.payload);
      target
        ? state.bookmarkedFbIdList?.splice(state.bookmarkedFbIdList.indexOf(target), 1)
        : state.bookmarkedFbIdList?.push(action.payload);
    },
    updateMenuRouter(state, action: PayloadAction<EIDCardOperationOptions | null>) {
      state.menuRouter = action.payload;
    },
    updateCustomRelations(state, action: PayloadAction<TRelation>) {
      const relationIndex = state.customRelations?.findIndex(
        (c) => c.relationId === action.payload.relationId
      );
      if (relationIndex !== -1) {
        state.customRelations[relationIndex] = action.payload;
      } else {
        state.customRelations.push(action.payload);
      }
    },
    temporaryConnectionSave(state, action: PayloadAction<TTemporaryRelation>) {
      state.connectionStatus = action.payload;
    },
  },

  extraReducers: (builder) => {
    // builder.addCase("persist/REHYDRATE", (state, action: any) => {
    //   if (!action.payload.user?.userId) {
    //     console.warn(" // Merge the rehydrated state only if `userId` is empty");
    //     return { ...state, ...action.payload.user };
    //   } else {
    //     console.warn("Invalid user data during rehydration, resetting to initial state.");
    //     // return initialUserState;
    //   }
    // });
  },
});

export default userSlice.reducer;

export const {
  createUserInstance,
  toggleUpdateBookmarkedFbIdList,
  updateMenuRouter,
  updateCustomRelations,
  temporaryConnectionSave,
} = userSlice.actions;

export const sessionHandler = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
  "userSlice/sessionHandler",
  async (accessToken: string, { dispatch }) => {
    const res = await fetch("/api/v1/user?requestType=profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    });
    if (res.ok) {
      const data: SejongUser = await res.json();
      dispatch(saveUserAction(data));
    } else {
      dispatch(logoutUserHandler());
    }
  }
);

export const logoutUserHandler = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  "userSlice/logoutHandler",
  async (_, { dispatch }) => {
    try {
      // Sign out using NextAuth
      await signOut();

      // After successful sign-out, redirect to the Microsoft logout URL
      const locale = window.navigator.language || "en";
      const microsoftLogoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(window.location.origin + `/${locale}`)}`;

      window.location.href = microsoftLogoutUrl;
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  }
);

export const loggedInUserHandler = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
  "userSlice/sessionHandler",
  async (accessToken: string, { dispatch }) => {
    const res = await fetch("/api/v1/user?requestType=profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    });
    if (res.ok) {
      const data: SejongUser = await res.json();
      dispatch(createUserInstance({ email: data.userPrincipalName, name: data.displayName }));
    } else {
      dispatch(logoutUserHandler());
    }
  }
);

export const userProfilePictureHandler = createAsyncThunk<
  string,
  string,
  { dispatch: AppDispatch }
>("userSlice/userProfilePictureHandler", async (accessToken: string, { dispatch }) => {
  try {
    const response = await fetch("/api/v1/user?requestType=profile-picture", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    });
    const result = await response.json();
    if (response.ok) {
      const base64Image = `data:image/png;base64,${result.image}`;
      return base64Image;
    } else {
      return result.error;
    }
  } catch (err) {
    throw new Error("Failed to fetch the blob.");
  }
});

type TPromiseResponse = { statusCode: number; status: string; records: any[] };

export const saveUserAction = createAsyncThunk(
  "userSlice/saveUserAction",
  async (data: SejongUser, { dispatch, getState, rejectWithValue }) => {
    console.log("saveUserAction", data);
    try {
      const state = getState() as RootState;
      const user = state.user;
      const userId = user.userId!;
      if (!userId) return;
      const allUser = await fetch(
        `/api/v2/strapi?requestType=fetch_strapi_data&table=sejong-users`,
        {
          method: "GET",
        }
      );
      const responseData: TPromiseResponse = await allUser.json();
      const { statusCode, status, records } = responseData;

      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error duplicate user check");
      }
      const newUser = records.filter((record: any) => record.userId === userId).length === 0;
      newUser &&
        (await fetch("/api/v2/strapi", {
          method: "POST",
          body: JSON.stringify({
            requestType: "create_strapi_data",
            payload: {
              table: "sejong-users",
              data: user,
            },
          }),
        }));
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

// todo
export const updateUserBookmarkedFactblockListToDatabase = createAsyncThunk(
  "userSlice/updateUserBookmarkedFactblockListToDatabase",
  async (data: string[], { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.user;
      const userId = user.userId!;
      if (!userId) return;
      // save to database with the corresponding user information
      // refer to "User" table for superuser
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const addConnection = createAsyncThunk(
  "userSlice/addConnection",
  async (data: TTemporaryRelation, { dispatch, rejectWithValue }) => {
    if (!data.source) return;

    const ServiceDBDataArray: TRelation[] = data.destination.map((destination) => {
      const relationType = data.arrow[destination.fbId] as `${Relation}`;
      const transformedData: TRelation = {
        relationId: uuidv4(),
        destinationFbId: destination.fbId,
        sourceFbId: data.source!.fbId,
        userId: SUPERUSER,
        relationType: relationType ?? Relation.Null,
        updateTime: Date.now().toString(),
      };

      return transformedData;
    });

    const transformDataToVectorDbSavable = (
      sourceFbId: string,
      sourceFbContent: string,
      destinations: TRelation[]
    ) => {
      const linkage: { [key: string]: string[] } = {};

      // Loop through the array of destinations and group them by relationType
      destinations.forEach((destination) => {
        const fbPath = `legal-base/linkage/${destination.destinationFbId}`;
        if (!linkage[destination.relationType]) {
          linkage[destination.relationType] = [];
        }
        linkage[destination.relationType].push(fbPath); // Add each fbPath to the appropriate relationType
      });
      // console.log("linkage", linkage);
      // Return the transformed data structure for vector DB upsert
      return {
        user_id: SUPERUSER, // Pass the SUPERUSER or relevant user ID
        upsert_data: [
          {
            fb_id: sourceFbId, // Use sourceFbId for fb_id (FactBlock source)
            linkage: linkage, // The linkage object contains multiple destinations grouped by relationType
            content: sourceFbContent,
          },
        ],
      };
    };
    // Example usage:
    const vectorDBSavable = transformDataToVectorDbSavable(
      data.source!.fbId,
      data.source!.originalContent,
      ServiceDBDataArray
    );
    // console.log(vectorDBSavable);

    try {
      for (let serviceDbDataForm of ServiceDBDataArray) {
        const query = `/api/v2/strapi?requestType=fetch_strapi_data&table=relations&params=filters[destinationFbId][$eq]=${serviceDbDataForm.destinationFbId}&filters[sourceFbId][$eq]=${serviceDbDataForm.sourceFbId}&filters[userId][$eq]=${serviceDbDataForm.userId}`;
        const response = await fetch(query);
        const responseData: TPromiseResponse = await response.json();
        const { statusCode, status, records } = responseData;

        if (status !== "success" || statusCode !== 200) {
          throw new Error("Error fetching matching relations");
        }

        if (records.length > 0) {
          fetch("/api/v2/strapi", {
            method: "PUT",
            body: JSON.stringify({
              requestType: "update_strapi_data",
              payload: {
                table: "relations",
                data: { ...serviceDbDataForm, relationId: records[0].relationId },
                id: records[0].id,
              },
            }),
          });
        } else {
          fetch("/api/v2/strapi", {
            method: "POST",
            body: JSON.stringify({
              requestType: "create_strapi_data",
              payload: { table: "relations", data: serviceDbDataForm },
            }),
          });
        }
        dispatch(updateCustomRelations(serviceDbDataForm));

        await fetch(`/api/v1/factBlock`, {
          method: "POST",
          body: JSON.stringify({
            requestType: "upsert_factblock_with_linkage",
            payload: vectorDBSavable,
          }),
        });
      }
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;

export const getRelations = createAsyncThunk(
  "userSlice/getRelations",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const query = `/api/v2/strapi?requestType=fetch_strapi_data&table=relations&params=filters[userId][$eq]=${SUPERUSER}`;
    try {
      const response = await fetch(query);
      const responseData: TPromiseResponse = await response.json();
      const { statusCode, status, records } = responseData;
      if (status !== "success" || statusCode !== 200) {
        throw new Error("Error fetching matching relations");
      }
      if (records.length > 0) {
        records.map((r) => dispatch(updateCustomRelations(r as any)));
        return records;
      }
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
) as any;
